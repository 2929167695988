import { fetchAsync } from "actions/AsyncAction";
import { ThunkDispatch } from "tdispatch";
import Api from "utilities/api";
import * as Actions from "./index";

/** 部署の一覧を取得する */
export function fetchDepartments() {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    const params = {
      excludeRoot: true,
    };
    try {
      const data = await dispatch(fetchAsync(Api.departments.index, params));
      dispatch(Actions.setGroups(data.departments));
    } catch (e) {
      /** fetchAsync で例外処理済みのため何もしない */
    }
  };
}

/** ownerIdのを持つユーザーの所属部署の一覧を取得する */
export function fetchUserDepartments(ownerId: string) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    const params = {
      memberId: ownerId,
    };

    try {
      const data = await dispatch(
        fetchAsync(Api.members.departments.index, params),
      );
      dispatch(Actions.setUserDepartments(data.departments));
    } catch (e) {
      /** fetchAsync で例外処理済みのため何もしない */
    }
  };
}
