import React from 'react';
import colors from 'values/colors';
import styled from 'styled-components';
import { HeaderGroup } from 'react-table';
import { TableSortIcon } from 'components/renewaled_ui/Table/atoms/SortIcon';
import { TableSortIconBlue } from 'components/renewaled_ui/Table/atoms/SortIconBlue';
import { Th } from 'components/renewaled_ui/Table/atoms/Th';
import { useHooks } from './hooks';

type TableHeaderGroup = HeaderGroup & { readonly filterContent?: JSX.Element };

const gray = "#d0d3d3";
const Thead = styled.thead`
  position: sticky;
  user-select: auto;
  top: -1px;
  font-weight: 600;
  // ./IndeterminateCheckboxが1でそれより上である必要があり、2。
  z-index: 2;

  &:hover {
    .resizer {
      transition: 500ms;
      background: ${gray};
    }
  }
`;
interface HeaderTrProps {
  readonly isSimpleDesignMode: boolean;
}
const HeaderTr = styled.tr<HeaderTrProps>`
  background: ${colors.renewaled.whiteHover};

  [data-sticky-td] {
    position: sticky;
    background-color: ${(p): string =>
      p.isSimpleDesignMode
        ? colors.renewaled.whiteHover
        : colors.renewaled.whiteHover};
  }
`;
const Resizer = styled.div`
  display: inline-block;
  width: 8px;
  height: 100%;
  position: absolute;
  right: 4px;
  top: 0;
  transform: translateX(50%);

  &.isResizing {
    transition: 100ms;
    background #b8bebe !important;
  }
`;
const DNDTh = styled.th`
  width: 40px;
`;
interface ThContentProps {
  readonly isFirstTh: boolean;
  readonly isPointer: boolean;
}
const ThContent = styled.div<ThContentProps>`
  width: 100%;
  cursor: ${(p): string => (p.isPointer ? "pointer" : "auto")};
  ${(p): string => (p.isFirstTh ? "" : "overflow: hidden;")}
`;

interface Props {
  /** 行のドラッグ&ドロップを有効にするか */
  readonly useRowDragAndDrop?: boolean;
  /** 列幅変更するか */
  readonly columnResizable?: boolean;
  /** headerGroup */
  readonly headerGroup: HeaderGroup;
  /** ローディング中 */
  readonly isLoading: boolean | undefined;
  /** 枠線がないシンプルなデザイン */
  readonly isSimpleDesignMode: boolean;
  /** 請求書刷新でのテーブルデザイン */
  readonly isReportItemDesignMode: boolean;
  /** ソート実行時のハンドラ */
  readonly onSortChange?: (sortName: string, sortOrder: "ASC" | "DESC") => void;
}

/**
 * テーブルヘッダー
 */
export const TableHeader: React.FC<Props> = (props) => {
  const { headerProps, onClickHeader } = useHooks(props.onSortChange);
  
  return (
    <Thead className='thead'>
      <HeaderTr
        className='tr'
        isSimpleDesignMode={ props.isSimpleDesignMode }
        { ...props.headerGroup.getHeaderGroupProps() }
        style={ {
          ...props.headerGroup.getHeaderGroupProps().style,
          display: 'table-row',
        } }
      >
        {/* ドラックするなら、ヘッダーにアイコンのTHを追加 */}
        { props.useRowDragAndDrop && <DNDTh className='th' /> }

        {/* ヘッダー一覧 */}
        { props.headerGroup.headers.map((column: TableHeaderGroup, index: number) => {
          const isSort = column.defaultCanSort;
          const canResize = props.columnResizable && column.id !== 'selection';
          const isFilter = Object.prototype.hasOwnProperty.call(column, 'filterContent') && !!column.filterContent;

          return (
            <Th
              tableHeaderProps={ column.getHeaderProps(headerProps) }
              key={ column.getHeaderProps().key }
              isSimpleDesignMode={ props.isSimpleDesignMode }
              isReportItemDesignMode={ props.isReportItemDesignMode }
              isBorderLeft={ index !== 0 }
            >
              <>
                <ThContent
                  isPointer={ isSort || isFilter }
                  onClick={ (): void => onClickHeader(column) }
                  isFirstTh={ column.id === 'selection' }
                >
                  { !isFilter && column.render('Header') }

                  {
                    // ソート
                    isSort && !props.isReportItemDesignMode && <TableSortIcon
                      isSorted={ column.isSorted }
                      isSortedDesc={ column.isSortedDesc }
                    />
                  }
                  {
                    // ソート
                    isSort && props.isReportItemDesignMode && <TableSortIconBlue
                      isSorted={ column.isSorted }
                      isSortedDesc={ column.isSortedDesc }
                    />
                  }
                </ThContent>

                { isFilter && column.render('filterContent') }

                {
                  // 横幅可変
                  canResize && (
                    <Resizer
                      { ...column.getResizerProps() }
                      className={ `resizer ${
                        // リサイザーを掴んで、サイズ変更中
                        column.isResizing ? 'isResizing' : ''
                      }` }
                    />
                  )
                }
              </>
            </Th>
          );
        }) }
      </HeaderTr>
    </Thead>
  );
};
